import React from "react";
import HeaderSocials from "../HeaderSocials";
import me from "../../assets/me.PNG";

const AboutMe = () => {
    return (
        <section id="about" className="about section">
            <div className="about-container container">
                <h1 className="title">About</h1>
                <div className="columns">
                    <div className="column is-2">
                        <HeaderSocials />
                    </div>
                    <div className="column is-5">
                        <div className="content">
                            <p>
                                Hey! Nice to meet you, my name is{" "}
                                <strong style={{ color: "white" }}>
                                    Ann Song
                                </strong>
                                , a senior at Choate Rosemary Hall, a leading
                                college preparatory high school in Wallingford,
                                Connecticut. I am interested in all things STEM,
                                primarily programming and robotics.
                            </p>
                            <p>Outside the classroom I am... </p>
                            <p>
                                <strong style={{ color: "gray" }}>
                                    Lead Programmer
                                </strong>{" "}
                                on{" "}
                                <a
                                    href="https://robotics.choate.edu/"
                                    target="_blank"
                                >
                                    FRC Team 7407
                                </a>{" "}
                            </p>
                            <p>
                                <strong style={{ color: "gray" }}>
                                    Independent Researcher
                                </strong>{" "}
                                in{" "}
                                <a
                                    href="https://robotics.choate.edu/"
                                    target="_blank"
                                >
                                    Alzheimer's Disease Progression
                                </a>{" "}
                            </p>
                            <p>
                                <strong style={{ color: "gray" }}>
                                    President of{" "}
                                </strong>
                                <a>GirlTech Club</a>, a school club promoting women in STEM.
                            </p>
                            <p>
                                <strong style={{ color: "gray" }}>
                                    Head of Website Engagement on{" "}
                                </strong>
                                <a
                                    href="https://thechoatenews.choate.edu/"
                                    target="_blank"
                                >
                                    The Choate News
                                </a>
                            </p>
                            <p>
                                <strong style={{ color: "gray" }}>
                                    Computer Science Instructor
                                </strong>{" "}
                                at{" "}
                                <a
                                    href="https://www.ktbyte.com/"
                                    target="_blank"
                                >
                                    KTBYTE Computer Science Academy
                                </a>
                                .
                            </p>
                            <p>and more!</p>
                        </div>
                    </div>
                    <div className="column is-5">
                        <div className="row mx-auto">
                            <div
                                className="col-sm-12 col-md-6 col-lg-4"
                                style={{ cursor: "pointer" }}
                            >
                                <span className="portfolio-item d-block">
                                    <div className="foto">
                                        <div className="me">
                                            <img
                                                src={me}
                                                alt="me"
                                                style={{
                                                    marginBottom: 0,
                                                    paddingBottom: 0,
                                                    position: "relative",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;
